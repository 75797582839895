<template>
    <div v-if="organization.key">
        <site-mapping
            :organization-key="organization.key"
            :organization="organization"
            class="my-6"
        />

        <consignments-sharing
            :organization-key="organization.key"
            :organization="organization"
            class="my-6"
        />

        <shipment-direction-mapping
            :organization-key="organization.key"
            :organization="organization"
            class="my-6"
        />
    </div>
</template>
<script>
import SiteMapping from '~/components/organization/SiteMapping';
import ShipmentDirectionMapping from '~/components/organization/ShipmentDirectionMapping';
import ConsignmentsSharing from '~/components/organization/ConsignmentsSharing';

export default {
    name: 'OrganizationRules',
    components: {
        SiteMapping,
        ShipmentDirectionMapping,
        ConsignmentsSharing,
    },
    props: {
        organization: {
            type: Object,
            default: null,
        },
    },
    computed: {
        edit() {
            return this.$route.name !== 'admin.organizations.create';
        },
    },
}
</script>
<style>
.rules-table-span {
    line-height: 38px;
}
</style>
export default {
    data() {
        return {
            tableRef: '',
            initialRows: [],
            rowsLength: 0,
            hasChanges: false,
        };
    },
    computed: {
        hasNewRows() {
            return this.$refs[this.tableRef]?.table?.rows.filter(row => !row.id).length;
        },
    },
    methods: {
        updateField({ row, key, value }) {
            row[key] = value;
            this.updateHasChanges();    
        },
        async updateHasChanges() {
            await this.$nextTick();
            this.hasChanges = !!this.$refs[this.tableRef].table.rows.find(tableRow => {
                if (!tableRow.id || !tableRow.owner_from_source) {
                    return false;
                }

                const match = this.initialRows.find(initialRow => tableRow.id === initialRow.id);

                return Object.keys(tableRow).find(key => tableRow.id && (tableRow[key] !== match[key]));
            });        
        },
        getChangedRows() {
            const changedRows = this.$refs[this.tableRef].table.rows.filter(tableRow => {
                if (!tableRow.id || !tableRow.owner_from_source) {
                    return false;
                }

                const match = this.initialRows.find(initialRow => tableRow.id === initialRow.id);

                return Object.keys(tableRow).filter(key => tableRow.id && (tableRow[key] !== match[key])).length;
            });

            return changedRows;
        },
        onRowsLoaded(rows, rowsCallback, postCallback) {
            if (rowsCallback) {
                rows.forEach(rowsCallback);
            }

            this.initialRows = JSON.parse(JSON.stringify(rows));
            this.rowsLength = rows.length;
            this.hasChanges = false;

            if (postCallback) {
                postCallback();
            }
        },
    },
};
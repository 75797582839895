<template>
    <card class="overflow-hidden border-radius mt-6" bodyClass="p-0 -mx-6" title="Branding" title-no-border>
        <div class="flex flex-wrap w-full px-6 pb-8 mt-4">
            <div class="relative flex flex-wrap w-1/2 pr-8 file-input">
                <modal-field label="Logotype" class="flex">
                    <label class="inline-block btn-sm cursor-pointer flex flex__align-center flex__justify-center" for="file" :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                        Select file </label>
                    <input class="hidden" id="file" type="file" name="file" accept="jpg/image/jpeg/image/png" @change="handleFile"/>
                </modal-field>
                <span class="flex items-end w-2/3 px-2 ml-2 text-gray-500 border-b w-80 file-info" :class="{'border-b border-red-500' : validationErrors.image}">
                    <span v-if="!fileInputName">No file has been uploaded yet</span>
                    <span class="text-gray-800" v-else>{{ fileInputName }}</span>
                </span>
                <transition name="fade">
                    <span v-if="validationErrors.image" class="absolute bottom-0 w-full -mb-6 text-red-500 help-block">
                        <span v-if="validationErrors.image">{{ validationErrors.image[0] }}</span>
                    </span>
                </transition>
            </div>

            <modal-field label="Primary color" class="w-1/3">
                <input type="text" v-model="brandColor" placeholder="Hex-color" @input="hexColor" class="prefix" :class="{'border-red-500' : validationErrors.brand_color}"/>
                <div class="absolute top-0 bottom-0 left-0 flex items-center h-full m-auto ml-4 text-gray-500 rounded-full">
                    #
                </div>
                <div class="absolute top-0 bottom-0 right-0 w-5 h-5 m-auto mr-4 rounded-full" :style="{backgroundColor: organization.brand_color}"></div>

                <transition name="fade">
                    <span v-if="validationErrors.brand_color" class="absolute w-full text-red-500 help-block">
                        <span>{{ validationErrors.brand_color[0] }}</span>
                    </span>
                </transition>
            </modal-field>

            <div class="flex w-1/2 mt-8">
                <span v-if="previewImage != null" class="flex block w-full">
                    <img :src="previewImage" class="w-1/3 uploading-image" :class="{'mt-4'  : validationErrors.image != null}">
                </span>
                <span v-if="organization.logo != null && previewImage == null" class="flex block w-full">
                    <img :src="organization.logo" class="w-1/3 uploading-image">
                </span>
            </div>
        </div>
    </card>    
</template>
<script>
export default {
    props: {
        organization: {
            type: Object,
            default: () => ({}),
        },
        validationErrors: {
            type: Object,
            default: () => ({}),
        },
        fileInput: File,
    },
    data() {
        return {
            fileInputName: '',
            previewImage: null,
            brandColor: null,
        }
    },
    watch: {
        'organization.brand_color'() {
            this.parseOrganization();
        },
    },
     model: {
        prop: 'file-input',
        event: 'update:file-input'
    },
    computed: {
        localFileInput: {
            set(value) {
                this.$emit('update:file-input', value);
            },
            get() {
                return this.fileInput;
            },
        },
        routeIncludesAdmin() {
            return this.$route.path.includes('admin');
        },
    },
    methods: {
        handleFile(file) {
            this.fileInputName = file.target.files[0].name;
            this.localFileInput = file.target.files[0];
            
            const image = file.target.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = file => {
                this.previewImage = file.target.result;
            };
        },
        hexColor() {
            if (this.brandColor !== '') {
                this.organization.brand_color = '#' + this.brandColor;
            } else {
                this.organization.brand_color = '';
            }
        },
        parseOrganization() {
            this.brandColor = this.organization ? this.organization.brand_color : '';
            this.brandColor = this.brandColor.replace('#', '');
        },
    },
}
</script>

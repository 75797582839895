<template>
    <div>
        <card class="overflow-hidden border-radius mt-6" bodyClass="p-0 -mx-6 min-h-80 organization-sites" title="Mark-ups" title-no-border>
            <template v-if="isGodUser" slot="tools">
                <button @click="openAddMarkup">+ Add new mark-up</button>
                <button @click="saveMarkups" :disabled="!hasMarkupsChanges && !hasMarkupsVisibilityChange" class="btn-primary ml-5">Save</button>
            </template>
            <div>
                <modal-field label="Markup visibility" class="w-1/2 px-6 mb-4 mt-4">
                    <input type="number" v-model="organization.markup_visibility" @change="updateMarkupVisibility"/>
                </modal-field>
                <v-table
                    id="markups"
                    :columns="columnMarkups"
                    :tools="false"
                    :editable="true"
                    row-key="id"
                    sort-key="carrier"
                    :sort-order="1"
                    ref="markups"
                    :endpoint="markupsEndpoint"
                    :filter-params="{ 'filter[organization_id]': $route.params.id }"
                    no-cache
                    static
                    :on-rows-loaded="onMarkupRowsLoaded"
                    @updated-sort-key="updateMarkupsTable"
                    @updated-sort-order="updateMarkupsTable"
                >
                    <template slot="cell" slot-scope="{row, col}">
                        <span v-if="col.key === 'booking_enabled'">
                            <v-checkbox
                                class="mt-2"
                                :value="row[col.key]"
                                @click="updateMarkupBookingEnabled(row.id, $event)"
                            />
                        </span>
                        <span v-else-if="col.key === 'rate'">
                            <input
                                type="number"
                                v-model="row[col.key]"
                                @input="updateMarkupValue(col.key, row.id, $event.target.value)"
                                @change="updateDomDom(row.id, $event.target.value)"
                            >
                        </span>
                        <span v-else-if="col.key === 'fixed'">
                            <input
                                type="number"
                                v-model="row[col.key]"
                                @input="updateMarkupValue(col.key, row.id, $event.target.value)"
                            >
                        </span>
                        <span v-else-if="col.key === 'geography_specific_rates'" class="cursor-pointer pr-2 pl-2 markup-specifics-value" @click="openMarkup(row.id)">
                            {{ hasGeographySpecifics(row[col.key]) }}
                        </span>
                        <span v-else-if="col.key === 'carrier'">
                            {{ row[col.key].name }}
                        </span>
                        <span v-else-if="col.key === 'carrier_service'">
                            {{ row[col.key].name }}
                        </span>
                        <span v-else-if="col.key === 'service_description'">
                            <input
                                type="text"
                                v-model="row[col.key]"
                                @input="updateMarkupValue(col.key, row.id, $event.target.value)"
                            >
                        </span>
                        <span v-else-if="col.key === '_actions'">
                            <div class="text-right">
                                <span class="cursor-pointer" @click="showRemoveMarkup(row)">
                                    <svg-importer icon-name="icons/close" key="markup-delete" width="20" />
                                </span>
                            </div>
                        </span>
                        <span v-else>
                            {{ row[col.key] }}
                        </span>
                    </template>
                </v-table>
            </div>
        </card>

        <add-report-modal
            :show="showAddMarkup"
            @hide="showAddMarkup = false"
            @success="fetchMarkups"
            @loading="loading = newStatus"
            :organization="this.organization"
            size="lg"
        ></add-report-modal>

        <markup-modal
            :show="isShowMarkupModal"
            size="md"
            @hide="hideMarkup"
            @save="updateMarkupSpecifics"
        >
        </markup-modal>

        <add-markup-modal
            ref="addMarkupModal"
            :show="isShowAddMarkupModal"
            :loading="isMarkupLoading"
            :carrier-services-exclude="carrierServicesExclude"
            size="md"
            @hide="hideAddMarkup"
            @save="createMarkup"
        />

        <delete-modal
            :show="isShowDeleteMarkupModal"
            name="Markup"
            @hide="closeDeleteMarkupModal"
            @delete="removeMarkup"
        />
    </div>
</template>
<script>
import { DEFAULT_MARKUP_GEOGRAPHY_SPECIFICS_VALUE } from '~/store/modules/markups.js';
import markupsColumnsData from '~/data/columns-markup';

import VCheckbox from '~/components/VCheckbox.vue';

import axios from 'axios';
import { requestErrors } from '~/utils/errors';

const booleanMap = new Map([[true, 'Yes'], [false, 'No']]);

export default {
    components: {
        VCheckbox,
    },
    props: {
        organization: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            isShowDeleteMarkupModal: false,
            isMarkupLoading: false,
            hasMarkupsChanges: false,
            hasMarkupsVisibilityChange: false,
            changedMarkups: new Set(),
            selectedMarkup: null,
            showAddMarkup: false,
            isShowMarkupModal: false,
            isShowAddMarkupModal: false,
            carrierServicesExclude: [],
            isEmitLoaded: true,
        }
    },
    computed: {
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        markupsEndpoint() {
            return `${this.$apiUrl.carrierServices.base}/markup`;
        },
        columnMarkups() {
            const columns = [...markupsColumnsData];

            columns.push({
                title: '',
                key: '_actions',
                toggled: true,
                sortable: false,
                width: 'w-12',
            });

            return columns;
        },
    },
    methods: {
        updateMarkupsTable() {
            this.$refs.markups?.table?.fetchRows();
        },
        onMarkupRowsLoaded(rows) {
            this.markups = rows;
            this.carrierServicesExclude = rows.map(cs => cs.carrier_service?.id);

            if (this.isEmitLoaded) {
                this.isEmitLoaded = false;
            }
        },
        getHumanReadableBoolean(value) {
            return booleanMap.get(typeof value === 'boolean' ? value : Boolean(value));
        },
        hasGeographySpecifics(cell) {
            return this.getHumanReadableBoolean(
                    Object.values(cell).find(field => field),
            );
        },
        toggleAddNewMarkup() {
            this.showAddMarkup = !this.showAddMarkup;
        },
        updateMarkupVisibility(event) {
            const value = +event.target.value;
            this.organization.markup_visibility = Math.round(value);

            if (value < 0 || event.target.value === '') {
                this.organization.markup_visibility = 0;

                return;
            }

            if (value > 100) {
                this.organization.markup_visibility = 100;

                return;
            }

            this.hasMarkupsVisibilityChange = true;
        },
        prepareMarkupRequestData(markup) {
            return {
                carrier_service_id: markup.carrier_service.id,
                data: {
                    organization_id: this.$route.params.id,
                    booking_enabled: Boolean(markup.booking_enabled),
                    rate: markup.rate || null,
                    fixed: markup.fixed || null,
                    geography_specific_rates: markup.geography_specific_rates || DEFAULT_MARKUP_GEOGRAPHY_SPECIFICS_VALUE,
                    service_description: markup.service_description,
                },
            };
        },
        getMarkupEditUrl(carrierServiceId) {
            return `${this.$apiUrl.carrierServices.base}/${carrierServiceId}/markup`;
        },
        closeDeleteMarkupModal() {
            this.selectedMarkup = null;
            this.isShowDeleteMarkupModal = false;
        },
        showRemoveMarkup(data) {
            this.selectedMarkup = data;
            this.isShowDeleteMarkupModal = true;
        },
        async removeMarkup() {
            const payload = { data: { organization_id: this.$route.params.id } };
            try {
                await axios.delete(this.getMarkupEditUrl(this.selectedMarkup.carrier_service.id), payload);

                this.fetchMarkups();

                this.isShowDeleteMarkupModal = false;
                this.selectedMarkup = null;
                
                this.$snotify.success('Success');
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }
        },
        async saveMarkups() {
            if (this.hasMarkupsVisibilityChange) {
                this.$emit('change', this.organization.markup_visibility);   
            }

            const changedMarkups = Array.from(this.changedMarkups);

            if (!changedMarkups.length) {
                return;
            }

            const data = this.markups
                .filter(markup => changedMarkups.includes(markup.id))
                .map(markup => this.prepareMarkupRequestData(markup));
            const promises = data.map(item => axios.patch(this.getMarkupEditUrl(item.carrier_service_id), item.data))

            try {
                await Promise.all(promises);
                this.resetChangedMarkups();
                
                this.fetchMarkups();

                this.$snotify.success('Changes saved');

            } catch (error) {
                this.$snotify.error(`Failed to update mark-up service: ${requestErrors(error)}`);
            }
        },
        updateMarkupBookingEnabled(id, value) {
            this.markups = this.markups.map(markup => {
                if (markup.id !== id) {
                    return markup;
                }

                return {
                    ...markup,
                    booking_enabled: value,
                }
            });

            this.changedMarkups.add(id);
            this.hasMarkupsChanges = true;
        },
        updateDomDom(id, value) {
            this.markups = this.markups.map(markup => {
                if (markup.id !== id) {
                    return markup;
                }

                if (value && !markup.geography_specific_rates.DOM_DOM) {
                    markup.geography_specific_rates.DOM_DOM = value;
                }

                return markup;
            });
        },
        async updateMarkupValue(field, id, value) {
            this.markups = this.markups.map(markup => {
                if (markup.id !== id) {
                    return markup;
                }

                markup[field] = value;

                return markup;
            });

            this.changedMarkups.add(id);
            this.hasMarkupsChanges = true;
        },
        openMarkup(id) {
            const markup = this.markups.find(markup => markup.id === id);

            this.$store.commit('markups/SET_ID', markup.id);
            this.$store.commit('markups/SET_BOOKING_ENABLED', markup.booking_enabled);
            this.$store.commit('markups/SET_CARRIER', markup.carrier);
            this.$store.commit('markups/SET_CARRIER_SERVICE', markup.carrier_service);
            this.$store.commit('markups/SET_RATE', markup.rate);
            this.$store.commit('markups/SET_FIXED', markup.fixed);
            this.$store.commit('markups/SET_GEOGRAPHY_RATES', markup.geography_specific_rates);

            this.isShowMarkupModal = true;
        },
        hideMarkup() {
            this.$store.dispatch('markups/resetState');
            this.isShowMarkupModal = false;
        },
        updateMarkupSpecifics(data) {
            this.markups = this.markups.map(markup => {
                if (markup.id !== data.id) {
                    return markup;
                }

                markup.geography_specific_rates = data.geography_specific_rates;

                return markup
            });

            this.changedMarkups.add(data.id);
            this.hasMarkupsChanges = true;
            this.$store.dispatch('markups/resetState');
        },
        openAddMarkup() {
            this.isShowAddMarkupModal = true;
        },
        hideAddMarkup() {
            this.isShowAddMarkupModal = false;
        },
        async createMarkup(data) {
            this.isMarkupLoading = true;

            try {
                const newMarkup = this.prepareMarkupRequestData(data);
                await axios.post('/api/carrier-services/markup/bulk', { ...newMarkup.data, carrier_services: data.carrier_service });
                this.fetchMarkups();
                this.$refs.addMarkupModal.hide();

                this.$snotify.success('Changes saved');
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isMarkupLoading = false;
        },
        async fetchMarkups() {
            await this.$refs?.markups?.table?.fetchRows();
        },
        resetChangedMarkups() {
            this.changedMarkups.clear();
            this.hasMarkupsChanges = false;
        },
    },
}
</script>
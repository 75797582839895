<template>
    <div>
        <card class="border-radius mt-6" bodyClass="p-0 -mx-6 min-h-80 organization-sites" title="Sites" title-no-border>
            <template v-if="isAdmin" slot="tools">
                <button @click="toggleAddNewSite">+ Add new site</button>
                <button @click="saveSites" :disabled="!hasSitesChanges" class="btn-primary ml-5">Save</button>
            </template>

            <div class="flex flex-wrap w-full">
                <v-table
                    id="sites"
                    :columns="columnSites"
                    :tools="false"
                    :editable="true"
                    row-key="uuid"
                    ref="sites"
                    :endpoint="endpoint"
                    sort-key="created_at"
                    :filter-params="filterParams"
                    no-cache
                    static
                >
                    <template slot="cell" slot-scope="{row, col}">
                        <span v-if="col.key === 'users'" class="dotted-underline">
                            {{ row[col.key].length }} users
                        </span>
                        <template v-else-if="col.key === 'excluded_carrier_options' && isGodUser">
                            <organization-select
                                v-if="hasCarrierOptions"
                                :label="col.title"
                                :options="carrierServiceOptions"
                                :site-id="row.id"
                                :model="row[col.key] || []"
                                @changed="updateSiteExcludedCarriers"
                            ></organization-select>
                        </template>
                        <template v-else-if="col.key === 'excluded_carrier_options' && !isGodUser">
                            <div class="pre-wrap">
                                {{ excludedCarrierServicesString(row[col.key] || []) }}
                            </div>
                        </template>
                        <span v-else-if="col.key === 'remove' && isAdmin" class="flex justify-end w-full">
                            <router-link :to="`/settings/site/edit/${row.id}`" class="mr-8">
                                <svg-importer icon-name="icons/edit" width="24" />
                            </router-link>
                            <button @click="removeSite(row.id)">
                                <svg-importer icon-name="icons/close" key="organization-remove-close" width="20" />
                            </button>
                        </span>
                        <span v-else>{{ row[col.key] }}</span>
                    </template>

                    <template slot="empty">
                        <div class="flex items-center justify-center w-full min-h-64">
                            <div>
                                <div class="flex justify-center w-full fa-6x">
                                    <fa :icon="['fal','building']" class="block text-gray-500"/>
                                </div>
                                <p class="w-full mt-2 text-center text-gray-500">No sites have been added yet</p>
                            </div>
                        </div>
                    </template>

                    <div v-if="isAdmin" slot="bottom-tools" class="p-5 text-right">
                        <button @click="saveSites" :disabled="!hasSitesChanges" class="btn-primary ml-4">Save</button>
                    </div>
                </v-table>
            </div>
        </card> 

        <delete-modal
            :show="showDeleteModal"
            @hide="closeDeleteModal"
            name="Site"
            :selectedObject="selectedSite"
            :organization="organization"
            :sites="sites"
            @delete="initDelete"
        />
        <add-site-modal
            :show="showAddSite"
            @hide="showAddSite = false"
            @success="fetchSites()"
            @loading="loading = newStatus"
            :organization="organization"
            :carriers="carrierOptions"
            :carrier-services="carrierServiceOptions"
            size="lg"
        ></add-site-modal>
    </div>
</template>
<script>
import OrganizationSelect from './OrganizationSelect';

import sitesColumnsData from '~/data/columns-site';

import axios from 'axios';

export default {
    components: {
        OrganizationSelect,
    },
    props: {
        organization: {
            type: Object,
            default: () => null,
        },
        carrierServiceOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            columns: sitesColumnsData,
            changedSites: {},
            loading: false,
            showDeleteModal: false,
            selectedSite: null,
            sites: null,
            filterParams: {},
            carrierOptions: [],
            hasSitesChanges: false,
            showAddSite: false,
        }
    },
    computed: {
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        edit() {
            return this.$route.name !== 'admin.organizations.create';
        },
        endpoint() {
            return `${this.$apiUrl.organizations.base}/sites/${this.$route.params.id}`;
        },
        hasCarrierOptions() {
            return this.carrierOptions.length > 0;
        },
        columnSites() {
            const columns = [...sitesColumnsData];

            if (this.isAdmin) {
                columns.push({
                    title: '',
                    key: 'remove',
                    toggled: true,
                    sortable: false,
                    width: 'w-1/5'
                });
            }

            return columns;
        },
    },
    methods: {
        async initDelete(deleteAction) {
            this.deleteAction = deleteAction;

            if (deleteAction.command === 'move') {
                await this.moveUsers();
            } else {
                await this.deleteSite();
            }

            this.fetchSites();
        },
        toggleAddNewSite() {
            this.showAddSite = !this.showAddSite;
        },
        closeDeleteModal() {
            this.showDeleteModal = false;
            this.selectedSite = null;
            this.showMoveUsers = false;
        },
        async deleteSite() {
            try {
                await axios.delete(`${this.$apiUrl.sites.base}/` + this.selectedSite.id);
                this.$refs.sites.table.fetchRows();
                this.closeDeleteModal();
            } catch (e) {
                console.log(e, 'error');
            }
        },
        async moveUsers() {
            let params = {
                newSite: this.deleteAction.newSite.id,
            };
            try {
                await axios.post(`${this.$apiUrl.sites.base}/` + this.selectedSite.id + '/move-users/', params);
                this.$refs.sites.table.fetchRows();
                this.closeDeleteModal();
            } catch (e) {
                console.log(e, 'error');
            }
        },
        removeSite(id) {
            this.showDeleteModal = true;
            this.selectedSite = this.sites.find(site => site.id === id);
        },
        async fetchSites() {
            const {data} = await axios.get(this.endpoint + `?&pageSize=1000&include=users`);

            this.sites = data.data;
            this.$refs.sites.table.fetchRows();
        },
        async saveSite() {
            this.loading = true;

            if (!this.$route.params.id || !this.edit) {
                return;
            }

            let site_data = {};
            this.modal.forEach(element => {
                if (element.key === 'name' && element.value != null) {
                    site_data['key'] = element.value.split(' ').join('_');
                }

                site_data[element.key] = element.value;
            });
            site_data['organization_id'] = this.organization.id;
            try {
                await axios.post(this.$apiUrl.sites.base, site_data);
                this.showAddSite = false;
                this.$refs.sites.table.fetchRows();
                this.fetchSites();
                this.clearSiteForm();
                this.$snotify.success(`Site created`);
                this.validationSiteErrors = [];
                this.loading = false;
            } catch (error) {
                this.loading = false;
                if (error.response.status === 422) {
                    this.validationSiteErrors = error.response.data.errors;
                }
            }
        },
        async saveSites() {
            if (!this.hasSitesChanges) {
                return;
            }
            axios.patch(this.$apiUrl.sites.base + '/bunch', this.changedSites).then(() => {
                this.resetChangedSites();
                this.$snotify.success(`Sites saved`);

                this.$emit('fetch');
            });
        },
        resetChangedSites() {
            this.changedSites = {};
            this.hasSitesChanges = false;
        },
        excludedCarrierServicesString(services) {
            if (!services) {
                return;
            }

            return services.map(service => service.name).join(', ');
        },
        updateSiteExcludedCarriers({ siteId, modelsList }) {
            if (!this.changedSites[siteId]) {
                this.changedSites[siteId] = {
                    site_id: siteId,
                };
            }
            this.changedSites[siteId].excluded_carrier_options = modelsList;
            this.hasSitesChanges = true;
        },
        async fetchCarrierOptions() {
            if (!this.isAdmin) {
                return;
            }

            const {data: { data }} = await axios.get(this.$apiUrl.carriers.base + '?pageSize=10000');
            this.carrierOptions = data;
        },
    },
    async created() {
        if (!this.edit) {
            return;
        }

        this.fetchCarrierOptions();
    }
}
</script>

export const DEFAULT_ROW = {
    owner_from_source: null,
    priority: 0,
    owner_site_from_source: null,
    payer: '',
    carrier_from_source: '',
    pickup_name: '',
    delivery_name: '',
    pickup_countrycode: '',
    delivery_countrycode: '',
    pickup_postcode: '',
    delivery_postcode: '',
    pickup_city: '',
    delivery_city: '',
    consignor_name: '',
    consignee_name: '',
    consignor_countrycode: '',
    consignee_countrycode: '',
    consignor_postcode: '',
    consignee_postcode: '',
    is_active: 0,
};

export const AUTOCOMPLETE_FIELD_NAMES = ['countrycode', 'postcode', 'city', 'name'];

export const PAYLOAD_IGNORE_FIELDS = ['id', 'updated_at', 'created_at'];
export default {
    watch: {
        toggleableColumns(columns, oldColumns) {
            if (!oldColumns?.length) {
                return;
            }

            localStorage.setItem(this.lsColumnsName, JSON.stringify(columns));
        },
    },
    data() {
        return {
            tableRef: '',
            toggleableColumns: [],
        };
    },
    computed: {
        lsColumnsName() {
            return `${this.tableRef}_columns_v1`;
        },
    },
    methods: {
        setToggleableColumns() {
            const lsColumns = localStorage.getItem(this.lsColumnsName);

            if (lsColumns) {
                this.toggleableColumns = JSON.parse(lsColumns);
                this.$refs[this.tableRef].table.columns = this.toggleableColumns;

                return;
            }
    
            this.toggleableColumns = this.$refs[this.tableRef].table?.columns;
            localStorage.setItem(this.lsColumnsName, JSON.stringify(this.toggleableColumns));
        },
        updateColumns() {
            const findFirstPin = this.toggleableColumns.findIndex(item => {
                return !item.pin;
            });

            this.toggleableColumns.forEach((item, index) => {
                if (findFirstPin != -1 && index > findFirstPin) {
                    item.pin = false;
                }

                if (index > 2) {
                    item.pin = false;
                }

                item.index = index;
            });

            this.$refs[this.tableRef].table.columns = this.toggleableColumns;
        },
        columnsSwitchUpdated() {
            setTimeout(async () => {
                await this.updateColumns();
            }, 250)
        },
        updateColumnCheckbox(col) {
            setTimeout(() => { col.toggled = !col.toggled });
        },
    },
}
<template>
    <div class="flex items-end">
        <modal-field class="w-2/3 tags-wrapper" label="Daily pickup key words">
            <div class="relative text-sm leading-tight">
                <div tabindex="-1" class="multiselect relative no-branding multi-select_dynamic">
                    <div class="multiselect__tags">
                        <div class="multiselect__tags-wrap" style="">
                            <span v-for="(item, index) in modelValue" :key="`keyword_${index}`" class="multiselect__tag">
                                <span>{{ item }}</span>
                                <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(index)" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </modal-field>
        <button type="button" class="ml-2 no-wrap add-btn" @click="isModalShow = true">
            + Add new keyword
        </button>
        <modal
            :show="isModalShow" 
            :click-to-close="true"
            size="sm"
            @hide="closeModal"
        >
            <template slot="body">
                <div class="flex items-end pb-8">
                    <modal-field label="Add new keyboard">
                        <input v-model="newKeyword" type="text">
                    </modal-field>
                    <button class="btn-primary ml-4" @click="addNewKeyword">
                        Add
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            value: this.modelValue,
            isModalShow: false,
            newKeyword: '',
            componentCounter: 0,
        }
    },

    watch: {
        modelValue(val) {
            this.value = val;
        },
    },

    model: {
        prop: 'modelValue',
        event: 'click'
    },

    methods: {
        remove(index) {
            this.value.splice(index, 1);
            this.updateValue();
        },
        
        async updateValue(value) {
            this.$emit('click', value || this.value);
        },

        closeModal() {
            this.isModalShow = false;
        },

        addNewKeyword() {
            this.value.push(this.newKeyword);
            this.newKeyword = '';

            this.updateValue();

            this.closeModal();
        },
    },

}
</script>
<style>
.tags-wrapper {
    min-width: 300px;
}

.add-btn {
    margin-bottom: 0.4rem;
}
</style>
<template>
    <div class="pb-6">
        <page-header-tabs
            :title="this.$route.params.id ? '' : 'Add organization'"
            :tabs="tabs"
            :current-tab="$route.query.section"
        >
            <template v-if="currentTab === 'info'">
                <router-link :to="{ name: 'admin.organizations'}" class="inline-block mr-4 btn-transparent">Cancel
                </router-link>
                <button :disabled="!allowSave" @click="saveOrganization" :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']">
                    Save
                </button>
            </template>
        </page-header-tabs>

        <transition name="fade">
            <div v-if="!organization" class="flex justify-center my-8">
                <app-spinner />
            </div>
        </transition>

        <div v-if="currentTab === 'info'">
            <card
                class="overflow-hidden border-radius"
                :class="{ 'mt-6': isAdmin }"
                bodyClass="p-0 -mx-6 overflow-inherit"
                title="General information"
                title-no-border
            >
                <div class="flex w-full px-6 pb-8 mt-4 flex-wrap">
                <modal-field label="Name" class="w-1/2 pr-8">
                    <input
                    type="text"
                    v-model="organization.name"
                    :class="{ 'border-red-500': validationErrors.name }"
                    />

                    <transition name="fade">
                    <span
                        v-if="validationErrors.name"
                        class="w-full text-red-500 help-block"
                    >
                        <span>{{ validationErrors.name[0] }}</span>
                    </span>
                    </transition>
                </modal-field>

                <modal-field label="Identifier" class="w-1/2">
                    <input
                    type="text"
                    v-model="organization.key"
                    :class="{ 'border-red-500': validationErrors.key }"
                    />

                    <transition name="fade">
                    <span
                        v-if="validationErrors.key"
                        class="w-full text-red-500 help-block"
                    >
                        <span>{{ validationErrors.key[0] }}</span>
                    </span>
                    </transition>
                </modal-field>

                <modal-field label="Azure company name" class="w-1/2 mt-4 pr-8">
                    <input
                    type="text"
                    v-model="organization.azure_company_name"
                    :class="{ 'border-red-500': validationErrors.azure_company_name }"
                    />

                    <transition name="fade">
                    <span
                        v-if="validationErrors.azure_company_name"
                        class="w-full text-red-500 help-block"
                    >
                        <span>{{ validationErrors.azure_company_name[0] }}</span>
                    </span>
                    </transition>
                </modal-field>

                <div class="w-1/2 mt-4">
                    <modal-field label="Country">
                    <multiselect
                        v-model="organization.country"
                        :options="countries"
                        class="relative"
                        :class="{ 'outline-red-500': validationErrors.country_code }"
                        label="name"
                    >
                        <template slot="option" slot-scope="props">
                        {{ props.option.name }}
                        </template>
                    </multiselect>
                    </modal-field>
                </div>

                <div v-if="isGodUser" class="w-1/2 mt-4">
                    <modal-field class="mr-8" label="Is onboarded">
                    <checkbox v-model="organization.is_onboarded" class="mt-2" />
                    </modal-field>
                </div>

                <div v-if="isGodUser" class="flex flex__align-center w-1/2 mt-4 pr-8">
                    <modal-field label="Common excluded carriers services" class="w-full">
                    <multiselect
                        v-model="commonExcludedCarriers"
                        :options="carrierServiceOptions"
                        multiple
                        track-by="id"
                        label="name"
                        :disabled="!edit || !sitesLength"
                        @select="updateCarrierServiceOptions"
                        @remove="deleteCarrierServiceOptions({ value: $event })"
                    >
                    </multiselect>
                    </modal-field>
                    <button
                    :disabled="
                        !organization.common_excluded_carrier_service_ids ||
                        !organization.common_excluded_carrier_service_ids.length
                    "
                    class="btn ml-4"
                    style="
                        height: 33px;
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-top: 15px;
                    "
                    @click="deleteCarrierServiceOptions({ all: true })"
                    >
                    Clear all
                    </button>
                </div>

                <div class="flex items-end pr-8 mt-4 w-3/4">
                    <KeywordsMultiSelect v-model="organization.daily_pick_up_markers" />
                </div>

                <div v-if="isGodUser" class="w-full mt-6 pt-6 border__top-grey">
                    <h4 class="capitalize">Booking</h4>

                    <modal-field label="Username" class="w-1/2 pr-8 mt-4">
                    <input
                        v-model="organization.booking_username"
                        type="text"
                        :class="{
                        'is-invalid':
                            $v.organization.booking_name &&
                            $v.organization.booking_name.$invalid,
                        }"
                    />
                    </modal-field>

                    <modal-field label="Group" class="w-1/2 pr-8 mt-4">
                    <input
                        v-model="organization.booking_group"
                        type="text"
                        :class="{
                        'is-invalid':
                            $v.organization.booking_group &&
                            $v.organization.booking_group.$invalid,
                        }"
                    />
                    </modal-field>

                    <div class="w-1/2 pr-8 mt-4 flex flex__align-center">
                    <modal-field label="Password" class="w-full">
                        <input
                        v-model="organization.booking_password"
                        :disabled="!isChangeBookingPassword && edit"
                        :type="isChangeBookingPassword ? 'text' : 'password'"
                        :class="{
                            'is-invalid':
                            $v.organization.booking_password &&
                            $v.organization.booking_password.$invalid,
                        }"
                        />
                    </modal-field>

                    <button
                        v-if="!isChangeBookingPassword && edit"
                        class="btn ml-4"
                        style="
                        height: 33px;
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-top: 15px;
                        "
                        @click="isChangeBookingPassword = true"
                    >
                        Change password
                    </button>
                    </div>
                </div>
                </div>
            </card>

            <branding
                v-model="fileInput"
                :organization="organization"
                :validation-errors="validationErrors"
            />
        </div>

        <block-sites
            v-if="edit && currentTab === 'sites'"
            :organization="organization"
            :validation-errors="validationErrors"
            :carrier-service-options="carrierServiceOptions"
        />

        <block-markups
            v-if="edit && isGodUser && currentTab === 'markups'"
            :organization="organization"
            @change="changeMarkupVisibility"
        />

        <block-reports
            v-if="edit && currentTab === 'reports'"
            :organization="organization"
        />

        <block-rules v-if="edit && currentTab === 'rules'" :organization="organization" />
    </div>
</template>

<script>
import axios from 'axios';
import { getUniqueCarrierServiceOptions } from '~/utils/carrier-service-options.js';
import KeywordsMultiSelect from '~/components/KeywordsMultiSelect';
import PageHeaderTabs from "~/components/PageHeaderTabs";

import Branding from '~/components/organization/Branding';
import BlockSites from '~/components/organization/Sites';
import BlockReports from '~/components/organization/Reports';
import BlockMarkups from '~/components/organization/Markups';
import BlockRules from '~/components/organization/blocks/Rules.vue';

import AppSpinner from '~/components/AppSpinner.vue';

import { requestErrors } from '~/utils/errors';
import { requiredIf } from 'vuelidate/lib/validators';

const tabsKeysDictionary = {
    info: 'info',
    sites: 'sites',
    markups: 'markups',
    reports: 'reports',
    rules: 'rules',
};

export default {
    name: 'AddOrganization',
    components: {
        KeywordsMultiSelect,
        Branding,
        BlockSites,
        BlockReports,
        BlockMarkups,
        BlockRules,
        AppSpinner,
        PageHeaderTabs,
    },
    metaInfo() {
        return {title: 'Add organization'};
    },
    watch: {
        $route(val) {
            this.currentTab = val.query.section || tabsKeysDictionary.info;
        },
    },
    data: () => ({
        loadingStatus: 0,
        sites: [],
        organization: {
            id: null,
            name: null,
            key: null,
            logo_url: null,
            brand_color: null,
            markup_visibility: 0,
            powerbi_report_workspace_id: null,
            powerbi_report_id: null,
            country: null,
            azure_company_name: null,
            is_onboarded: false,
        },
        validationErrors: {},
        isChangeBookingPassword: false,
        commonExcludedCarriers: [],
        carrierServiceOptions: [],
        organizationBeforeChange: {},
        loading: false,
        validationErrors: {},
        validationSiteErrors: {},
        showDeleteModal: false,
        newSite: null,
        selectedSite: null,
        showMoveUsers: false,
        application2: false,
        application3: false,
        showAddSite: false,
        fileInput: null,
        deleteAction: null,
        pickupKeywords: [],
        keywordsModalShow: false,
        newKeyword: '',
        tabsNamesMap: new Map([
            [tabsKeysDictionary.info, 'Info'],
            [tabsKeysDictionary.sites, 'Sites'],
            [tabsKeysDictionary.markups, 'Mark-ups'],
            [tabsKeysDictionary.reports, 'Reports'],
            [tabsKeysDictionary.rules, 'Rules'],
        ]),
        currentTab: '',
    }),
    validations: {
        organization: {
            booking_name: {
                required: requiredIf(function() {
                    if (this.organization?.booking_username?.length && this.organization?.booking_username?.length <= 100) {
                        return false;
                    }

                    return this.organization?.booking_group?.length || this.organization?.booking_password?.length;
                }),
            },
            booking_group: {
                required: requiredIf(function() {
                    if (this.organization?.booking_group?.length && this.organization?.booking_group?.length <= 100) {
                        return false;
                    }

                    return this.organization?.booking_username?.length || this.organization?.booking_password?.length;
                }),
            },
            booking_password: {
                required: requiredIf(function() {
                    if (this.organization?.booking_password?.length && this.organization?.booking_password?.length <= 100) {
                        return false;
                    }

                    return this.organization?.booking_username?.length || this.organization?.booking_group?.length;
                }),
            }
        },
    },
    async created() {
        this.currentTab = this.$route.query.section || tabsKeysDictionary.info;
        
        await this.$store.dispatch('countries/init');

        if (this.edit) {
            await Promise.all([
                this.fetchOrganization(),
                this.fetchCarrierServiceOptions(),
            ]);

            await this.initExcludedCarrierService();

            return;
        }

        await this.fetchCarrierServiceOptions();
    },
    beforeDestroy() {
        this.$store.dispatch('markups/resetStateAll');
    },
    computed: {
        tabs() {
            return Object.keys(tabsKeysDictionary).reduce((output, key, index) => {
                const keyValue = tabsKeysDictionary[key];

                if (keyValue === 'markups' && !this.isGodUser) {
                    return output;
                }

                output[keyValue === 'info' ? '' : keyValue] = {
                    value: keyValue,
                    title: this.tabsNamesMap.get(keyValue),
                    routeName: this.getTabRoute(key, keyValue),
                };

                return output;
            }, {});
        },
        countries() {
            return this.$store.getters['countries/list'];
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        isAdminInterface() {
            return this.$route.name === 'admin.organizations.edit'
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        edit() {
            return this.$route.name !== 'admin.organizations.create';
        },
        routeIncludesAdmin() {
            return this.$route.path.includes('admin');
        },
        allowSave() {
            if (this.isGodUser && this.$v.$invalid) {
                return false;
            }
            
            if (JSON.stringify(this.organizationBeforeChange) !== JSON.stringify(this.organization)) {
                return true;
            }

            return this.fileInput != null;
        },
        hasCarrierServiceOptions() {
            return this.carrierServiceOptions.length > 0;
        },
        allSites() {
            return this.$refs['organization-sites']?.$refs['sites']?.table?.rows;
        },
        sitesLength() {
            return this.sites.length;
        },
    },
    methods: {
        getTabRoute(blockKey, sectionName) {
            return blockKey === tabsKeysDictionary.info
                ? this.$route.path
                : `${this.$route.path}?section=${sectionName}`;
        },
        incrementLoadingStatus() {
            this.loadingStatus++;
        },
        onSitesLoaded($event) {
            this.sites = $event;
            this.incrementLoadingStatus();
        },
        
        changeLoadStatus(newStatus) {
            this.loading = newStatus;
        },
        
        async fetchOrganization() {
            const { data: { data } } = await axios.get(`${this.$apiUrl.organizations.base}/${this.$route.params.id}?include=sites`);

            this.organization = { ...data, country: this.countries.find(country => country.code === data.country_code )};
            
            this.$store.dispatch('markups/updateCountryName', this.organization?.country?.name);

            this.organizationBeforeChange = JSON.parse(JSON.stringify(this.organization));
        },
        
        async fetchCarrierServiceOptions() {
            if (!this.isAdmin) {
                return;
            }

            const {data: { data }} = await axios.get(`${this.$apiUrl.carrierServices.base}?limit=10000`);

            this.carrierServiceOptions = getUniqueCarrierServiceOptions(data);
        },
        
        getOrganizationPayload(source) {
            let formData = new FormData();
            const data = Object.assign({}, source || this.organization);

            if (!this.isGodUser) {
                delete data.booking_username;
                delete data.booking_group;
                delete data.booking_password;
            }

            if (this.fileInput) {
                formData.append('image', this.fileInput);
            }

            formData.append('organization', JSON.stringify(data));

            if (this.organization.country?.code) {
                formData.append('country_code', this.organization.country.code);
            }

            return formData;
        },
        

        async changeMarkupVisibility(markupVisibility) {
            try {
                const payload = this.getOrganizationPayload({
                    ...JSON.parse(JSON.stringify(this.organizationBeforeChange)),
                    markup_visibility: markupVisibility,
                });

                await axios.post(
                    `${this.$apiUrl.organizations.base}/${this.$route.params.id}`,
                    payload,
                );

                this.hasMarkupsVisibilityChange = false;

                this.$snotify.success('Markup visibility updated');
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }
        },
        
        closeDeleteModal() {
            this.showDeleteModal = false;
            this.selectedSite = null;
            this.newOrganization = null;
            this.showMoveUsers = false;
        },

        async fetchOrganizationSimple() {
            const { data: { data } } = await axios.get(`${this.$apiUrl.organizations.base}/${this.$route.params.id}`)

            this.organization = { ...data, country: this.countries.find(country => country.code === data.country_code )};

            this.$store.dispatch('markups/updateCountryName', this.organization?.country?.name);

            this.organizationBeforeChange = JSON.parse(JSON.stringify(this.organization));

            this.initExcludedCarrierService();
        },

        excludedCarrierOptionsFilter(item) {
            return !this.organization.common_excluded_carrier_service_ids.some(_item => {
                return item.id === _item;
            })
        },

        async deleteCarrierServiceOptions(params) {
            const result = {};

            this.allSites.forEach(site => {
                result[site.id] = {
                    site_id: site.id,
                    excluded_carrier_options: site.excluded_carrier_options
                        .filter(params.all ? this.excludedCarrierOptionsFilter : (item) => item.id != params.value.id)
                        .map(item => ({ id: item.id, site_id: site.id })),
                }
            });

            axios
                .patch(this.$apiUrl.sites.base + '/bunch', result)
                .then(this.fetchOrganizationSimple);
        },

        async updateCarrierServiceOptions(value) {
            await axios.post(`/api/organizations/${this.organization.id}/exclude_carrier_service`, {
                carrier_service_id: value.id
            });

            this.fetchOrganizationSimple();
        },
        initExcludedCarrierService() {
            this.commonExcludedCarriers = this.carrierServiceOptions.filter(item => {
                return this.organization.common_excluded_carrier_service_ids.some(_item => {
                    return item.id === _item;
                });
            });
        },
        async saveOrganization() {
            if (this.isGodUser && this.$v.$invalid) {
                return;
            }

            this.loading = true;

            let url = this.$apiUrl.organizations.base;

            if (this.$route.params.id && this.edit) {
                let id = this.$route.params.id;
                url = `${this.$apiUrl.organizations.base}/` + id;
            }

            try {
                const formData = this.getOrganizationPayload();

                const {data} = await axios.post(url, formData);
                this.organization = { ...data.data, country: this.countries.find(country => country.code === data.data.country_code) };

                this.isChangeBookingPassword = Boolean(this.isGodUser && !this.organization.booking_password);

                if (this.organization.id === this.user.organization.id) {
                    this.$store.commit('auth/CHANGE_CLIENT_LOGO');
                }

                this.$store.dispatch('markups/updateCountryName', this.organization?.country?.name);

                let id = data.data.id;

                this.validationErrors = {};

                if (!this.edit) {
                    this.$router.push({name: 'admin.organizations.edit', params: {id: id}});
                }

                this.$store.commit('sidebar/UPDATED_ORGANIZATIONS', this.organization.id);

                this.fileInput = null;
                this.organizationBeforeChange = JSON.parse(JSON.stringify(this.organization));
                this.loading = false;
                this.$snotify.success(`Organization saved`);
                await this.$store.dispatch('auth/fetchUser');
            } catch (error) {
                this.loading = false;
                if (error?.response?.status === 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }
        },
    },
};
</script>
<style lang="scss">
.border-red-500 {
    border-color: #f56565 !important;
}

.outline-red-500 {
    outline: 1px solid #f56565;
}

.prefix {
    padding-left: 1.65rem !important;
}

.organization-sites {
    overflow-y: inherit;
}

.pre-wrap {
    white-space: pre-wrap;
}

.markup-specifics-value {
    &:hover {
        @apply text-green-600;
    }
}
</style>

import { requestErrors } from '~/utils/errors';
import axios from 'axios';

export default {
    data() {
        return {
            tableRef: '',
            isDraggingId: null,
            isLastRowStatic: false,
        }
    },
    computed: {
        localStoragaName() {
            return `draggable-${this.tableRef}_before-change`;
        }
    },
    methods: {
        onDraggableRowsSet(rows) {
            localStorage.setItem(this.localStoragaName, JSON.stringify(rows));

            return rows.map((row, index) => {
                row.priority = index + 1;
    
                return row;
            });
        },
        async onDraggableRowChange({ moved }) {
            const newIndex = moved.newIndex + 1;
            const table = this.$refs[this.tableRef]?.table;

            if (this.isLastRowStatic && table.rows.at(-1)?.priority === newIndex) {
                table.rows = JSON.parse(localStorage.getItem(this.localStoragaName));

                return;
            }

            try {
                const {id, payload} = this.preparePayload({ ...moved.element, priority: newIndex });

                await axios.post(this.endpoint + `/${id}`, payload);
                this.$refs[this.tableRef].table?.fetchRowsSilent();
                
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }
        },
    },
};
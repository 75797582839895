<template>
    <div>
        <card v-if="edit" class="overflow-hidden border-radius mt-6" bodyClass="p-0 -mx-6 min-h-80 organization-sites" title="Reports" title-no-border>
            <template v-if="isGodUser" slot="tools">
                <button @click="toggleAddNewReport">+ Add new report</button>
            </template>

            <v-table
                v-if="$route.params.id"
                id="reports"
                :columns="columnReports"
                :tools="false"
                :editable="false"
                row-key="report_id"
                ref="reports"
                :endpoint="reportsEndpoint"
                :filter-params="{ organization_id: $route.params.id }"
                no-cache
                static
            >
                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="col.key === 'edit'" class="block w-full pr-2 text-right">
                        <button @click="openEditReport(row)">
                            <svg-importer icon-name="icons/edit" key="organization-edit" />
                        </button>
                    </span>
                    <span v-else-if="col.key === 'remove'" class="block w-full text-right">
                        <button @click="openRemoveReport(row.id, row.name)">
                            <svg-importer icon-name="icons/close" key="organization-close" width="20" />
                        </button>
                    </span>
                    <span v-else-if="col.key === 'is_default'">
                        {{ getHumanReadableBoolean(row[col.key]) }}
                    </span>
                    <span v-else>
                        {{ row[col.key] }}
                    </span>
                </template>
            </v-table>
        </card>

        <add-report-modal
            :show="showAddReport"
            @hide="showAddReport = false"
            @success="fetchReports()"
            @loading="loading = $event"
            :organization="this.organization"
            size="lg"
        ></add-report-modal>

        <edit-report-modal
            :show="isShowEditReport"
            @hide="closeEditReport"
            @success="fetchReports()"
            :report="currentReport"
            :organization="this.organization"
            size="lg"
        ></edit-report-modal>

        <delete-modal
            :show="isShowDeleteReport"
            @hide="closeDeleteReportModal"
            name="Report"
            :selectedObject="selectedReport"
            @delete="deleteReport"
        />
    </div>
</template>
<script>
import reportsColumnsData from '~/data/columns-report';

import axios from 'axios';

const booleanMap = new Map([[true, 'Yes'], [false, 'No']]);

export default {
    props: {
        organization: {
            type: Object,
            default: () => {null},
        },
        sitesLength: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            loading: false,
            reports: [],
            hasReportsChanges: false,
            changedReports: {},
            selectedReport: null,
            showAddReport: false,
            isShowEditReport: false,
            currentReport: null,
            isShowDeleteReport: false,
            isEmitLoaded: true,
        }
    },
    computed: {
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        edit() {
            return this.$route.name !== 'admin.organizations.create';
        },
        columnReports() {
            let columns = reportsColumnsData;

            if (this.isGodUser) {
                columns = columns.concat([
                    {
                        title: ' ',
                        key: 'edit',
                        toggled: true,
                        sortable: false,
                        width: 'w-5',
                    },
                    {
                        title: '',
                        key: 'remove',
                        toggled: true,
                        sortable: false,
                        width: 'w-5',
                    },
                ]);
            }

            return columns;
        },
        reportsEndpoint() {
            return this.$apiUrl.powerBi.base;
        },
    },
    methods: {
        getHumanReadableBoolean(value) {
            return booleanMap.get(typeof value === 'boolean' ? value : Boolean(value));
        },
        async deleteReport() {
            try {
                await axios.delete(`${this.$apiUrl.powerBi.base}/` + this.selectedReport.id);
                this.fetchReports();
                this.closeDeleteReportModal();
            } catch (e) {
                console.log(e, 'error');
            }
        },
        openEditReport(report) {
            this.currentReport = report;
            
            this.isShowEditReport = true;
        },
        closeEditReport() {
            this.isShowEditReport = false;

            this.currentReport = null;
        },
        openRemoveReport(id, name) {
            this.selectedReport = { id, name, organization: this.organization };

            this.isShowDeleteReport = true;
        },
        closeDeleteReportModal() {
            this.isShowDeleteReport = false;
            this.selectedReport = null;
        },
        async fetchReports() {
            this.$refs?.reports?.table?.fetchRows();
            this.reports = this.$refs?.reports?.table?.rows;

            if (this.isEmitLoaded) {
                this.isEmitLoaded = false;
            }

        },
        toggleAddNewReport() {
            this.showAddReport = !this.showAddReport;
        },
        saveReports() {
            if (!this.hasReportsChanges) {
                return;
            }
            axios.patch(this.$apiUrl.powerBi + '/bunch', this.changedSites).then(() => {
                this.resetChangedSites();
                this.$snotify.success(`Sites saved`);
                this.fetchOrganizationSimple()
            });
        },
        resetChangedReports() {
            this.changedReports= {};
            this.hasReportsChanges = false;
        },
    },
};
</script>

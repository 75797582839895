<template>
  <card
    title="Site mapping rules (for internal shipments only)"
    title-class=""
    bodyClass="p-0"
    class="rules-mapping border-radius"
  >
    <template slot="tools">
      <div class="flex">
        <button v-if="isGodUser" class="btn" @click="recalculate">Recalculate sites for shipments</button>
        <button
            v-if="isAllowedToEdit"
            type="button"
            :disabled="!hasChanges || hasNewRules || isLoading"
            class="btn ml-2"
            :class="{ 'btn-loading': isLoading }"
            @click="isShowConfirm = true"
        >
            Save
        </button>
        <v-columns-switch
            v-model="toggleableColumns"
            :toggle-table-tools="isShowColumnsSwitch"
            @click="columnsSwitchUpdated"
            @show="isShowColumnsSwitch = $event"
            @update="updateColumnCheckbox"
            class="ml-2"
        />
      </div>
    </template>

    <div class="w-full">
        <v-table
            :columns="columns"
            :tools="false"
            :editable="true"
            row-key="id"
            :ref="tableRef"
            :endpoint="endpoint"
            :filter-params="{ 'filter[owner_from_source]': organizationKey }"
            sort-key="priority"
            :sort-order="1"
            no-cache
            static
            class="mb-2"
            :paginate-value="1000"
            :on-rows-loaded="rowsLoadedHandler"
            :on-draggable-rows-set="onDraggableRowsSet"
            @draggable-row-change="onDraggableRowChange"
        >
            <template slot="cell" slot-scope="{row, col, index}">
                <span v-if="dateFields.includes(col.key)" class="rules-table-span">
                    {{ formatDate(row[col.key]) }}
                </span>
                <div v-else-if="col.key === 'is_active'">
                    <span v-if="isLastRow(index) && row.id" />
                    <span v-else-if="!isAllowedToEdit && !isLastRow(index)">
                        <v-checkbox :value="row[col.key]" disabled />
                    </span>

                    <div v-else class="flex h-10">
                        <v-checkbox v-model="row[col.key]" @click="updateHasChanges" />
                        <button class="ml-2" @click="openRemoveModal(row.id)">
                            <svg-importer icon-name="icons/close" />
                        </button>
                    </div>
                </div>
                <div
                    v-else-if="col.key === 'owner_site_from_source'"
                    :key="`wrapper_${col.key}-${componentCounter}`"
                    :class="col.width"
                >
                    <input v-if="!isAllowedToEdit" type="text" :value="row[col.key]" />
                    <modal-field
                        v-else
                        required
                        compact
                    >
                        <multiselect
                            v-model="row[col.key]"
                            :options="sitesOptions"
                            open-direction="bottom"
                            :allow-empty="false"
                            @select="updateHasChanges"
                            @search-change="updateField({ row, key: col.key, value: $event })"
                        />
                    </modal-field>
                </div>
                <div v-else-if="col.key === 'owner_from_source'" :class="col.width" class="flex">
                    <span class="rules-table-span">{{ row[col.key] }}</span>
                </div>
                <div v-else-if="isLastRow(index) && row.id" :class="col.width" class="flex w-full">
                    <span v-if="col.key === 'priority'" v-text="row[col.key]" class="ml-6 rules-table-span" />
                    <input v-else type="text" :value="row[col.key]" disabled>
                </div>
                <div
                    v-else-if="col.key === 'payer'"
                    :key="`wrapper_${col.key}-${componentCounter}`"
                    :class="col.width"
                >
                    <multiselect
                        v-model="row[col.key]"
                        :options="payerOptions"
                        open-direction="bottom"
                        @select="updateHasChanges"
                    />
                </div>
                <div v-else-if="getIsAutocompleteField(col.key) && isAllowedToEdit">
                    <autocomplete
                        :key="`autocomplete_${col.key}_index`"
                        v-model="row[col.key]"
                        :endpoint="filterOptionsUrl({ role: col.key.split('_')[0], field: col.key.split('_')[1] })"
                        :search-trigger-chars-threshold="2"
                        @click="updateField({ row, key: col.key, value: $event })"
                    />
                </div>
                <div v-else-if="col.key === 'priority'" class="flex">
                    <button class="handle mr-2" @mousedown="isDraggingId = row.id">
                        <fa :icon="['fal','grip-lines']" style="width: 20px; height: 20px;" />
                    </button>
                    <span class="rules-table-span">
                        {{ row[col.key] }}
                    </span>
                </div>
                <div v-else :class="col.width">
                    <input
                        v-if="!isAllowedToEdit"
                        type="text"
                        disabled
                        :value="row[col.key]"
                    />
                    <input
                        v-else
                        :key="`input_${col.key}_index`"
                        type="text"
                        :value="row[col.key]"
                        placeholder="Enter value"
                        @blur="updateField({ row, key: col.key, value: $event.target.value })"
                    />
                </div>
            </template>
        </v-table>
        <template v-if="isAllowedToEdit">
            <button v-show="!hasNewRules" :disabled="!hasNewRules && hasChanges" type="button" class="btn w-full" @click="addEmptyRow">
                +Add new rule
            </button>
            <button
                v-show="hasNewRules"
                :disabled="(!hasNewRules && hasChanges) || isLoadingNew"
                type="button"
                class="btn w-full"
                :class="{ 'btn-loading': isLoadingNew }"
                @click="saveNewRule"
            >
                Save new rule
            </button>
        </template>
    </div>
    <delete-modal
        :show="isShowRemoveModal"
        name="site mapping rule"
        @hide="closeRemoveModal"
        @delete="removeRule"
    />
    <modal :show="isShowConfirm" @hide="isShowConfirm = false" size="sm">
        <h4 slot="header" class="">Are you sure?</h4>

        <div slot="body">
            <p v-text="confirmText" />
        </div>

        <div slot="footer" class="flex w-full">
            <div class="ml-auto">
                <button type="button" class="btn-transparent" @click="isShowConfirm = false">Cancel</button>
                <button type="button" class="btn-primary ml-4" @click="saveChanged">Confirm</button>
            </div>
        </div>
    </modal>
  </card>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import columns from './siteMappingColumns.json';
import Autocomplete from '~/components/Autocomplete';
import Multiselect from 'vue-multiselect'
import VCheckbox from '~/components/VCheckbox.vue';
import { requestErrors } from '~/utils/errors';
import VColumnsSwitch from '@/components/vtable/VColumnsSwitch.vue';
import MappingMixin from '~/mixins/mappingTable.mixin';
import ToggleableColumnsMixin from '@/components/vtable/toggleableColumns.mixin.js';
import { DEFAULT_ROW, AUTOCOMPLETE_FIELD_NAMES, PAYLOAD_IGNORE_FIELDS } from '~/data/site-mapping';
import DraggableRowsMixin from '@/mixins/draggablePriority.mixin.js';
const preparePayloadRow = (row) => { 
    const rule = {};

    Object.entries(row).forEach(([key, value]) => {
        if (PAYLOAD_IGNORE_FIELDS.includes(key)) {
            return;
        }

        if (key === 'priority') {
            rule[key] = +row[key];
            
            return;
        }

        rule[key] = key === 'is_active' ? value : value || '*';
    });

    return rule;
};

export default {
    components: {
        Autocomplete,
        VCheckbox,
        VColumnsSwitch,
        Multiselect,
    },
    mixins: [ToggleableColumnsMixin, MappingMixin, DraggableRowsMixin],
    props: {
        organization: {
            type: Object,
            default: null,
        },
        organizationKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tableRef: 'rules',
            componentCounter: 0,
            columns,
            data: [],
            endpoint: this.$apiUrl.consignmentImports.rules.sites,
            hasNewRules: false,
            rowInFocus: null,
            currentRemoveId: null,
            isShowRemoveModal: false,
            isShowColumnsSwitch: false,
            organizationsOptions: [],
            organizations: [],
            sitesOptions: {},
            confirmText: 'This will bring about major changes',
            isShowConfirm: false,
            payerOptions: ['consignor', 'consignee', 'third party'],
            dateFields: ['updated_at', 'created_at'],
            isLoadingNew: false,
            isLoading: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        isAllowedToEdit() {
            return this.isGodUser || (this.$store.getters['auth/isOrgAdmin'] && this.user.organization.id === this.organizationId)
        },
        organizationId() {
            return this.$route.params.id;
        },
    },
    methods: {
        isLastRow(index) {
            return index === this.rowsLength - 1;
        },
        hasLength() {
            return this.rowsLength > 1;
        },
        getIsAutocompleteField(fieldName) {
            return AUTOCOMPLETE_FIELD_NAMES.find(name => fieldName.indexOf(name) !== -1);
        },
        rowsLoadedHandler(rows) {
            this.onRowsLoaded(rows, row => row.is_active = Number(row.is_active), this.setSitesOptions);
        },
        setSitesOptions() {
            this.sitesOptions = this.organization.sites.map(site => site.key);
        },
        async chooseOwner(owner, row) {
            row.owner_from_source = owner;
            row.owner_site_from_source = '';

            const matchOrganization = this.organizations.find(organization => organization.name === row[key]);
            if (!matchOrganization) {
                return;
            }

            if (this.sitesOptions[matchOrganization.name]) {
                return;
            }

            const { data: { data } } = await axios.get(this.$apiUrl.sites.base + `?filter[organization_id]=${matchOrganization.id}`);
            this.sitesOptions[matchOrganization.name] = data.map(item => item.name);
        },
        filterOptionsUrl({role, field}) {
            return (value) => {
                return this.$apiUrl.addressBook.filterOptions
                    + `?filter[organization_id]=${this.organizationId}`
                    + `&field=${field}`
                    + `&role=${role}`
                    + `&value=${value}`;
            };
        },
        formatDate(date) {
            if (!date) {
                return;
            }

            return moment(date).format('YYYY-MM-DD');
        },
        addEmptyRow() {
            const rows = this.$refs[this.tableRef].table?.rows;
            let priority = 1;

            if (rows[rows.length - 1]) {
                priority = rows[rows.length - 1].priority;
                rows[rows.length - 1].priority++;
            }

            this.$refs[this.tableRef].table?.rows.push({
                ...DEFAULT_ROW,
                owner_from_source: this.organizationKey,
                owner_site_from_source: this.sitesOptions[0],
                is_active: Number(!rows.length),
                priority
            });

            this.hasNewRules = true;
            this.rowsLength = this.$refs[this.tableRef].table?.rows.length;
        },
        async recalculate() {
            try {
                await axios.post(this.$apiUrl.consignmentImports.rules.recalculate + `/${this.organizationId}`);

                this.$snotify.success('Recalculation complete');
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }
        },
        preparePayload(row) {
            if (!row) {
                return;
            }

            return { id: row.id, payload: preparePayloadRow(row) };
        },
        async saveNewRule() {
            this.isLoadingNew = true;
            const newRule = this.$refs[this.tableRef].table?.rows
                .find(row => !row.id)

            try {
                await axios.post(this.endpoint, preparePayloadRow(newRule));

                this.$refs[this.tableRef].table?.fetchRowsSilent();

                this.$snotify.success('New rule creted');
                this.hasNewRules = false;
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isLoadingNew = false;
        },
        async saveChanged() {
            this.isLoading = true;
            this.isShowConfirm = false;

            const changedRows = this.getChangedRows();

            if (!changedRows.length) {
                this.$snotify.error('No rules to update');

                return;
            }

            const promises = [];
            changedRows.forEach(row => {
                const {id, payload} = this.preparePayload(row);
                promises.push(axios.post(this.endpoint + `/${id}`, payload));
            });

            try {
                await Promise.allSettled(promises);

                this.$refs[this.tableRef].table?.fetchRowsSilent();
                this.hasNewRules = false;

                this.$snotify.success('Rule(s) updated');
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isLoading = false;
        },
        openRemoveModal(id) {
            this.currentRemoveId = id;
            this.isShowRemoveModal = true;
        },
        closeRemoveModal() {
            this.currentRemoveId = null;
            this.isShowRemoveModal = false;
        },
        async removeRule() {
            this.isLoading = true;
            this.isLoadingNew = true;

            if (!this.currentRemoveId) {
                const rows = this.$refs[this.tableRef].table.rows;
                const toDeleteIndex = rows.findIndex(row => !row.id);
                this.$refs[this.tableRef].table.rows.splice(toDeleteIndex);

                this.rowsLength = this.$refs[this.tableRef].table?.rows.length;
                this.hasNewRules = false;
                this.isShowRemoveModal = false;

                return;
            }

            try {
                await axios.delete(this.endpoint + `/${this.currentRemoveId}`);
                
                this.$refs[this.tableRef].table?.fetchRows();
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isShowRemoveModal = false;
            this.currentRemoveId = null;
            this.isLoading = false;
            this.isLoadingNew = false;
        },
        async fetchOrganizations() {
            try {
                const { data: { data } } = await axios.get(`${this.$apiUrl.organizations.allowed}?pageSize="10000"`);
                this.organizationsOptions = data.map(item => item.name);
                this.organizations = data;
            } catch (error) {
                    this.$snotify.error(requestErrors(error));
            }
        },
    },
    created() {
        this.tableRef = 'rules';
        this.isLastRowStatic = true;
    },
    async mounted() {
        this.setToggleableColumns();
        await this.fetchOrganizations();
        this.setSitesOptions();
    },
}
</script>
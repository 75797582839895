<template>
    <modal-field
        class="pr-4"
        :label="label"
    >
        <multi-select
            select-label="name"
            select-value="id"
            :allow-clear="false"
            :allow-empty="true"
            :branding="false"
            :multiple="true"
            :options="options"
            v-model="modelValue"
            @change="select"
            @remove="remove"
        >
        </multi-select>
    </modal-field>
</template>

<script>
import MultiSelect from '~/components/MultiSelect';

export default {
    name: 'OrganizationSelect',
    components: {
        MultiSelect,
    },
    data() {
        return {
            modelValue: this.model,
        }
    },
    props: {
        siteId: {
            type: Number,
            required: true,
        },
        model: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
            default: (() => []),
        },
    },
    methods: {
        select(option) {
            let modelsList = this.cloneObject(this.modelValue);
            modelsList.push(this.cloneObject(option));
            this.change(modelsList);
        },
        remove(option) {
            let modelsList = this.cloneObject(this.modelValue);
            for (let i = 0; i < modelsList.length; ++i) {
                if (option.id === modelsList[i].id) {
                    modelsList.splice(i, 1);
                    break;
                }
            }
            this.change(modelsList);
        },
        change(modelsList) {
            let preparedModels = [];
            for (let i = 0; i < modelsList.length; ++i) {
                preparedModels.push({
                    site_id: this.siteId,
                    id: modelsList[i].id
                });
            }
            this.$emit('changed', {
                siteId : this.siteId,
                modelsList: preparedModels,
            });
        },
    },
};
</script>
